const { getCookie } = require('@netprofit/getcookie');

var cookieExpire = 2*60*60*1000; // 2 Stunden Gültigkeit

window.addEventListener("pageload", () => {
    init();
});

init();

function init(){
    document.querySelectorAll(".youtube-wrapper .overlay").forEach(overlay => {
        overlay.querySelector(".play").addEventListener("click", () => {
            const d = new Date();
            d.setTime(d.getTime() + cookieExpire);
            document.cookie = "youtube=true; expires=" + d.toUTCString() + "; path=/";
            overlay.style.display = "none";
            overlay.previousElementSibling.src = overlay.previousElementSibling.getAttribute("video-url");
        });
    });

    if(getCookie("youtube") == "true"){
        document.querySelectorAll(".youtube-wrapper .overlay").forEach(overlay => {
            overlay.style.display = "none";
            overlay.previousElementSibling.src = overlay.previousElementSibling.getAttribute("video-url");
        });
    }
}