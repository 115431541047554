// IE 11 forEach Polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

window.addEventListener("pageload", () => {
    init();
});

init();

function init(){
    var submenus = document.querySelectorAll("li.submenu");
    submenus.forEach(submenu => {
        setInterval(() => {
            checkPosition(submenu);
        }, 300);
    });
    function checkPosition(e){
        var elm = e.querySelector('div.submenu ul');
        if (elm != null) {
            var off = elm.getBoundingClientRect();
            var l = off.left;
            var w = elm.offsetWidth + 40;
            var docW = document.body.clientWidth;
            var isEntirelyVisible = (l + w <= docW);
            if (!isEntirelyVisible) {
                e.classList.add("edge");
            }
        }
    }
}