if(document.querySelectorAll(".mod_article > * > .inside, .mod_article > * > * > .inside, .mod_article > .mod_navigation_inline > .mod_navigation, .ce_rsce_gold_druckereien > .inside, .ce_rsce_form_verbesserung > .inside").length > 0){
    setInterval(() => {
        checkVisibility();
    },200);

    window.addEventListener('scroll', function(e) {
        checkVisibility();
    }, {passive: true});

    function checkVisibility(){
        document.querySelectorAll(".mod_article > * > .inside:not(.visible), .mod_article > * > * > .inside:not(.visible), .mod_article > .mod_navigation_inline > .mod_navigation:not(.visible), .ce_rsce_gold_druckereien > .inside:not(.visible), .ce_rsce_form_verbesserung > .inside:not(.visible)").forEach(function(element) {
            if(isElementXPercentInViewport(element, 5)) {
                element.classList.add("visible");
            }
        });
    }

    function isElementXPercentInViewport(el, percentVisible) {
        let rect = el.getBoundingClientRect(),
            windowHeight = window.innerHeight;

        return !(
            Math.floor(100 - ((rect.top / -rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        );
    }
}
