var type = getUrlParameter('gutschein');
if (type) {
    var typeElem = document.querySelector("#" + type);
    var code = typeElem.getAttribute("code");
    typeElem.parentElement.innerHTML = "<span class='active-link'>" + code + "</span>";
    typeElem.remove();
}

document.querySelectorAll(".gutschein").forEach(gutschein => {
    gutschein.addEventListener("click", () => {
        if (!gutschein.classList.contains("active-link")) {
            window.open(gutschein.getAttribute("codeurl"));
        } else return false;
    });
});


function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
}