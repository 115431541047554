var Swiper = require('swiper/cjs/components/core/core-class').default;
var Autoplay = require('swiper/cjs/components/autoplay/autoplay').default;
var Pagination = require('swiper/cjs/components/pagination/pagination').default;

window.addEventListener("pageload", () => {
    init();
});

init();

function init(){
    Swiper.use([Autoplay, Pagination]);
    
    document.querySelectorAll(".ce_rsce_teaser").forEach(teaser => {
        if(teaser.querySelectorAll(".swiper-slide").length > 1){
            new Swiper(teaser.querySelector(".slider"), {
                autoplay: {
                    delay: 5000,
                },
                direction: 'horizontal',
                centeredSlides: true,
                loop: true,
                speed: 400,
                pagination: {
                    el: ".swiper-dots",
                },
            });
        }
    });
}
