import barba from '@barba/core';
import { gsap } from "gsap";

barba.init({
    transitions: [{
        name: 'opacity-transition',
        sync: false,
        once(data) {
            setTimeout(() => {
                document.body.classList.remove("wait");
            }, 400);
            return gsap.to(".loading-screen", {opacity: 0, duration: .3, delay: .3});
        },
        leave(data) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);
            document.body.classList.add("wait");
            return gsap.to(".loading-screen", {opacity: 1, duration: .3});
        },
        after(data) {
            const event = new Event('pageload');
            window.dispatchEvent(event);

            document.body.classList.remove("dont-scroll");
            setTimeout(() => {
                document.body.classList.remove("wait");
            }, 400);
            return gsap.to(".loading-screen", {opacity: 0, duration: .3, delay: .4});
        }
    }]
});