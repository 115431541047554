window.addEventListener("scroll", function () {
    if(window.scrollY > 200){
        var navigation = document.querySelector("header .mobile-nav");
        if(!navigation.classList.contains("active")){
            document.body.classList.add("scrolled");
        }
    }else if (window.scrollY < 20) {
        document.body.classList.remove("scrolled");
    }
}, {passive: true});
