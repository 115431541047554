window.addEventListener("pageload", () => {
    init();
});

init();

function init(){
    var hamburger = document.querySelector(".hamburger");
    hamburger.addEventListener("click", () => {
        var navigation = document.querySelector("header .mobile-nav");
        if(navigation.classList.contains("active")){
            navigation.classList.remove("active");
            hamburger.classList.remove("is-active");
            document.body.classList.remove("dont-scroll");
            if(window.scrollY > 200){
                document.body.classList.add("scrolled");
            }
            document.querySelector("header").style.top = "";
        }else{
            navigation.classList.add("active");
            hamburger.classList.add("is-active");
            document.body.classList.add("dont-scroll");
            document.body.classList.remove("scrolled");
            document.querySelector("header").style.top = "0px";
        }
    });


    var toggles = document.querySelectorAll(".submenu-toggle");
    toggles.forEach(toggle => {
        toggle.addEventListener("click", () => {
            var submenu = toggle.nextElementSibling;
            if(toggle.classList.contains("active")){
                toggle.classList.remove("active");
                submenu.classList.remove("active");
            }else{
                toggle.classList.add("active");
                submenu.classList.add("active");
            }
        });    
    });
}